import { writable, derived } from "svelte/store";
import api from "../api";

const dst = writable([]);
export const loadDestinations = () => {
  return api.destinations().then((response) => {
    const data = Object.entries(response.data).map(([key, value]) => {
      return {
        ...value,
        name: key,
      };
    });
    dst.set(data);
  });
};

export const destinations = derived(dst, ($dst) => $dst);
