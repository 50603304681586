import { writable, derived } from "svelte/store";
import api from "../api";
import { propertiesMap } from "./properties";

const ev = writable([]);

export const loadEvents = () => {
  return api.events().then((response) => {
    ev.set(response.data);
  });
};

export const events = derived([ev, propertiesMap], ([$ev, $propertiesMap]) => {
  if (!Object.keys($propertiesMap).length) {
    return [];
  }

  return $ev
    .map((item) => {
      return {
        ...item,
        prop: $propertiesMap[item.zoopla_id],
      };
    })
    .filter((item) => !!item.prop);
});
