<script>
  import { createEventDispatcher } from "svelte";

  import MapboxDefaultMarker from "./MapboxDefaultMarker.svelte";

  export let property;
  export let active;

  const COLOR_DEFAULT = "#99bbad";
  const COLOR_ACTIVE = "#111111";
  const COLOR_WANTED = "#DD0000";
  const COLOR_SEEN = "#4a47a3";
  const COLOR_HIDDEN = "#cccccc";
  const COLOR_PINNED = "#fb743e";
  const COLOR_TRACKED = "#aae7ff";

  const dispatch = createEventDispatcher();

  let color = "#ccc";

  $: if (active) {
    color = COLOR_ACTIVE;
  } else if (property.hidden) {
    color = COLOR_HIDDEN;
  } else if (property.wanted) {
    color = COLOR_WANTED;
  } else if (property.tracked) {
    color = COLOR_TRACKED;
  } else if (property.seen) {
    color = COLOR_SEEN;
  } else if (property.pinned) {
    color = COLOR_PINNED;
  } else {
    color = COLOR_DEFAULT;
  }

  const propertyClick = () => {
    dispatch("click", property);
  };
</script>

<MapboxDefaultMarker
  on:click={propertyClick}
  {color}
  lat={property.location.lat}
  lng={property.location.lon}
/>
