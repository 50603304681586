<script>
  import { getContext } from "svelte";
  import { mapCtx } from "../Context";
  import { properties } from "../stores/properties";
  import { LngLatBounds } from "maplibre-gl";

  const map = getContext(mapCtx).getMap();
  let fit = false;

  $: if ($properties.length && !fit) {
    const bounds = new LngLatBounds();
    $properties.forEach((prop) => {
      bounds.extend([prop.location.lon, prop.location.lat]);
    });
    const panel = document.querySelector(".panel");
    const bottom = panel ? panel.clientHeight + 40 : 40;
    map.fitBounds(bounds, {
      padding: { top: 60, left: 40, right: 40, bottom },
    });
    fit = true;
  }
</script>
