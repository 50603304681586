import api from "../api";
import { derived, writable } from "svelte/store";
import { combinedFilters } from "./filters";

const dataLoading = writable(false);
export const properties = writable([]);

combinedFilters.subscribe(($combinedFilters) => {
  const { filters, specialFilter } = $combinedFilters;

  if (specialFilter) {
    if (specialFilter.pinnedOnly) {
      dataLoading.set(true);
      api
        .pinned()
        .then(({ data }) => {
          properties.set(data);
        })
        .finally(() => {
          dataLoading.set(false);
        });
    } else if (specialFilter.wantedOnly) {
      dataLoading.set(true);
      api
          .wanted()
          .then(({ data }) => {
            properties.set(data);
          })
          .finally(() => {
            dataLoading.set(false);
          });
    } else if (specialFilter.seenOnly) {
      dataLoading.set(true);
      api
          .seen()
          .then(({ data }) => {
            properties.set(data);
          })
          .finally(() => {
            dataLoading.set(false);
          });
    } else if (specialFilter.trackedOnly) {
      dataLoading.set(true);
      api
          .tracked()
          .then(({ data }) => {
            properties.set(data);
          })
          .finally(() => {
            dataLoading.set(false);
          });
    } else if (specialFilter.allMarked) {
      dataLoading.set(true);
      api
          .marked()
          .then(({ data }) => {
            properties.set(data);
          })
          .finally(() => {
            dataLoading.set(false);
          });
    } else if (specialFilter.propertyView) {
      dataLoading.set(true);
      api
        .property(specialFilter.propertyView)
        .then(({ data }) => {
          properties.set([data]);
        })
        .finally(() => {
          dataLoading.set(false);
        });
    }
  } else {
    const options = {
      min_price: filters.minPrice,
      max_price: filters.maxPrice,
      max_travel: filters.maxTravel,
      min_beds: filters.minBeds,
      max_beds: filters.maxBeds,
      min_baths: filters.minBaths,
      max_baths: filters.maxBaths,
      limit: 1000,
    };

    if (filters.seen) {
      options.show_seen = "1";
    }

    if (filters.hidden) {
      options.show_hidden = "1";
    }

    if (filters.pinned) {
      options.show_pinned = "1";
    }

    if (filters.tracked) {
      options.show_tracked = "1";
    }

    if (filters.wanted) {
      options.show_wanted = "1";
    }

    /*
    if (filters.openPlan) {
      options.open_plan_only = "1";
    }

    if (filters.unfurnished) {
      options.unfurnished_only = "1";
    }

    if (filters.floor_plans_only) {
      options.with_floor_plans_only = "1";
    }
    */

    if (filters.garden.value.id === 0) {
      options.garden = "1";
    } else if (filters.garden.value.id === 1) {
      options.garden = "0";
    }

    if (filters.acre.value.id === 0) {
      options.acre = "1";
    } else if (filters.acre.value.id === 1) {
      options.acre = "0";
    }

    if (filters.parking.value.id === 0) {
      options.off_street_parking = "1";
    } else if (filters.parking.value.id === 1) {
      options.off_street_parking = "0";
    }

    if (
      filters.propType &&
      filters.propType.value &&
      filters.propType.value.id !== 0
    ) {
      options.property_type = filters.propType.value.text;
    }

    if (filters.minAreaSqM > 0) {
      options.min_area_sq_m = filters.minAreaSqM;
    }
    if (filters.maxAreaSqM > 0) {
      options.max_area_sq_m = filters.maxAreaSqM;
    }

    if (filters.includeNoArea) {
      options.include_no_area = "1";
    }

    if (filters.includeNoCommute) {
      options.include_no_commute = "1";
    }

    dataLoading.set(true);
    api
      .filter(options)
      .then(({ data }) => {
        properties.set(data);
      })
      .finally(() => {
        dataLoading.set(false);
      });
  }
});

export const isLoading = derived(dataLoading, ($dataLoading) => {
  return $dataLoading;
});

export const propertiesMap = derived(properties, ($properties) => {
  return $properties.reduce((acc, item) => {
    acc[item.zoopla_id] = item;
    return acc;
  }, {});
});

const setPropertyOption = (zooplaID, optionName, value) => {
  return api.option(zooplaID, optionName, value).then(() => {
    properties.update((current) => {
      return current.map((item) => {
        if (item.zoopla_id === zooplaID) {
          item[optionName] = value;
        }
        return item;
      });
    });
  });
};

export const setWanted = (zooplaID, value) => {
  return setPropertyOption(zooplaID, "wanted", value);
};

export const setPinned = (zooplaID, value) => {
  return setPropertyOption(zooplaID, "pinned", value);
};

export const setHidden = (zooplaID, value) => {
  return setPropertyOption(zooplaID, "hidden", value);
};

export const setComment = (zooplaID, value) => {
  return setPropertyOption(zooplaID, "comment", value);
};

export const setSeen = (zooplaID, value) => {
  return setPropertyOption(zooplaID, "seen", value);
};

export const setTracked = (zooplaID, value) => {
  return setPropertyOption(zooplaID, "tracked", value);
};

export const loadPropertyFull = (zooplaID) => {
  return api.property(zooplaID).then((response) => {
    properties.update((current) => {
      return current.map((item) => {
        if (item.zoopla_id === zooplaID) {
          item = {
            ...item,
            ...response.data,
          };
        }
        return item;
      });
    });
  });
};
