<script>
  export let size = 80;
  export let thickness = 3;
  export let color = "#662211";

  let spinnerWrapStyle = "";
  let spinnerStyle = "";
  let textStyle = "";

  $: spinnerWrapStyle = `height: ${size}px`;
  $: spinnerStyle = `width: ${size}px; height: ${size}px; border: ${thickness}px solid ${color}; border-bottom-color: transparent;`;
  $: textStyle = `top: ${-size / 2 - 19}px`;
</script>

<div style={spinnerWrapStyle} class="spinner-wrap">
  <div style={spinnerStyle} class="spinner" />
  <div style={textStyle} class="text">
    <slot />
  </div>
</div>

<style>
  .spinner-wrap {
    width: 100%;
  }

  .spinner {
    margin: auto;
    border-radius: 50%;
    animation: spin 0.75s linear infinite;
  }

  .text {
    position: relative;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    text-shadow: -1px -1.5px 0 white, -1px 1px 0 white, 1px -1px 0 white,
      1px 1px 0 white;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }
</style>
