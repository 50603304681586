<script>
  import _ from "lodash";
  import { filters } from "../stores/filters";
  import { Link } from "svelte-routing";
  let localFilters = $filters;

  const updateFilters = _.debounce((newFilters) => {
    filters.set(newFilters);
  }, 300);

  $: updateFilters(localFilters);
</script>

<div class="filters-wrapper">
  <div class="filters">
    <div class="input-group input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Type</span>
      </div>
      {#if localFilters.propType}
        <select bind:value={localFilters.propType.value} class="custom-select">
          {#each localFilters.propType.options as option}
            <option value={option}>{option.label}</option>
          {/each}
        </select>
      {/if}
    </div>
    <div class="input-group input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Garden</span>
      </div>
      <select bind:value={localFilters.garden.value} class="custom-select">
        {#each localFilters.garden.options as option}
          <option value={option}>{option.text}</option>
        {/each}
      </select>
    </div>
    <div class="input-group input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Acre</span>
      </div>
      <select bind:value={localFilters.acre.value} class="custom-select">
        {#each localFilters.acre.options as option}
          <option value={option}>{option.text}</option>
        {/each}
      </select>
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Parking</span>
      </div>
      <select bind:value={localFilters.parking.value} class="custom-select">
        {#each localFilters.parking.options as option}
          <option value={option}>{option.text}</option>
        {/each}
      </select>
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Min Beds</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.minBeds}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Max Beds</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.maxBeds}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Min Baths</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.minBaths}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Max Baths</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.maxBaths}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Min Price</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.minPrice}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Max Price</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.maxPrice}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Max Travel</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.maxTravel}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Min Area (m²)</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.minAreaSqM}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Max Area (m²)</span>
      </div>
      <input
              class="form-control"
              type="number"
              bind:value={localFilters.maxAreaSqM}
      />
    </div>
    <div class="input-group mb-2 mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">Events</span>
      </div>
      <input
        class="form-control"
        type="number"
        bind:value={localFilters.events}
      />
    </div>
  </div>
  <div class="filters filters-checks">
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
              type="checkbox"
              class="custom-control-input"
              id="switchWanted"
              bind:checked={localFilters.wanted}
      />
      <label class="custom-control-label" for="switchWanted">Wanted</label>
    </div>
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
        type="checkbox"
        class="custom-control-input"
        id="switchPinned"
        bind:checked={localFilters.pinned}
      />
      <label class="custom-control-label" for="switchPinned">Pinned</label>
    </div>
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
        type="checkbox"
        class="custom-control-input"
        id="switchSeen"
        bind:checked={localFilters.seen}
      />
      <label class="custom-control-label" for="switchSeen">Seen</label>
    </div>
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
              type="checkbox"
              class="custom-control-input"
              id="switchTracked"
              bind:checked={localFilters.tracked}
      />
      <label class="custom-control-label" for="switchTracked">Tracked</label>
    </div>
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
        type="checkbox"
        class="custom-control-input"
        id="switchHidden"
        bind:checked={localFilters.hidden}
      />
      <label class="custom-control-label" for="switchHidden">Hidden</label>
    </div>
    <!--
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
        type="checkbox"
        class="custom-control-input"
        id="switchOpenPlan"
        bind:checked={localFilters.openPlan}
      />
      <label class="custom-control-label" for="switchOpenPlan">Open Plan</label>
    </div>
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
              type="checkbox"
              class="custom-control-input"
              id="switchUnfurnished"
              bind:checked={localFilters.unfurnished}
      />
      <label class="custom-control-label" for="switchUnfurnished">Unfurnished</label>
    </div>
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
              type="checkbox"
              class="custom-control-input"
              id="switchFloorPlans"
              bind:checked={localFilters.floor_plans_only}
      />
      <label class="custom-control-label" for="switchFloorPlans">With floor plans</label>
    </div>
    -->
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
              type="checkbox"
              class="custom-control-input"
              id="switchIncludeNoArea"
              bind:checked={localFilters.includeNoArea}
      />
      <label class="custom-control-label" for="switchIncludeNoArea">Include unknown floor area</label>
    </div>
    <div class="custom-control custom-switch mr-2 mb-2">
      <input
              type="checkbox"
              class="custom-control-input"
              id="switchIncludeNoCommute"
              bind:checked={localFilters.includeNoCommute}
      />
      <label class="custom-control-label" for="switchIncludeNoCommute">Include unknown commute</label>
    </div>
  </div>
  <div class="mb-2">
    <Link to="wanted_only">Show Wanted Only</Link>
  </div>
  <div class="mb-2">
    <Link to="pinned_only">Show Pinned Only</Link>
  </div>
  <div class="mb-2">
    <Link to="seen_only">Show Seen Only</Link>
  </div>
  <div class="mb-2">
    <Link to="tracked_only">Show Tracked Only</Link>
  </div>
  <div class="mb-2">
    <Link to="all_marked">Show All Marked</Link>
  </div>
  <div>
    <button class="btn btn-primary" on:click|stopPropagation={filters.set(localFilters)}>
      <i class="fas fa-redo-alt" />
    </button>
  </div>
</div>

<style>
  .filters-wrapper {
    border: 1px solid #ccc;
    padding: 8px;
    margin-bottom: 1em;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
  }

  .filters .input-group {
    width: 200px;
  }

  .filters span,
  .filters input,
  .filters select,
  .filters .custom-control-label {
    font-size: 0.9em;
  }
</style>
