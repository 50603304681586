<script>
  import AlertBox from "./components/AlertBox.svelte";
  import { Router, Route } from "svelte-routing";
  import SearchView from "./views/SearchView.svelte";
</script>

<div id="app">
  <Router>
    <Route path="/" component={SearchView} />
    <Route path="property/:zooplaID" let:params>
      <SearchView propertyView={true} zooplaID={params.zooplaID} />
    </Route>
    <Route path="wanted_only">
      <SearchView wantedOnly={true} />
    </Route>
    <Route path="pinned_only">
      <SearchView pinnedOnly={true} />
    </Route>
    <Route path="seen_only">
      <SearchView seenOnly={true} />
    </Route>
    <Route path="tracked_only">
      <SearchView trackedOnly={true} />
    </Route>
    <Route path="all_marked">
      <SearchView allMarked={true} />
    </Route>
  </Router>
  <AlertBox />
</div>

<style>
  #app {
    height: 100%;
    min-height: 100%;
  }
</style>
