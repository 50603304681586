<script>
  import { Link } from "svelte-routing";
  import {
    setHidden,
    setPinned,
    setWanted,
    setTracked,
    setComment,
    setSeen,
    loadPropertyFull,
  } from "../stores/properties";
  import _ from "lodash";
  import { createEventDispatcher, onMount } from "svelte";
  import SpinnerText from "./SpinnerText.svelte";
  export let property;
  export let active;
  let internalPropertyURL = "";

  let element;
  let routes;
  let googleMapURL;
  let comment = property.comment;
  let commentTextarea;
  let isCommentSending = false;
  let elementID = `property-${property.zoopla_id}`;

  const dispatch = createEventDispatcher();

  const clickWanted = () => {
    setWanted(property.zoopla_id, !property.wanted);
  };

  const clickPin = () => {
    setPinned(property.zoopla_id, !property.pinned);
  };

  const clickHide = () => {
    setHidden(property.zoopla_id, !property.hidden);
  };

  const clickSeen = () => {
    setSeen(property.zoopla_id, !property.seen);
  };

  const clickTracked = () => {
    setTracked(property.zoopla_id, !property.tracked);
  };

  const changeComment = _.debounce((value) => {
    isCommentSending = true;
    setComment(property.zoopla_id, value).finally(() => {
      isCommentSending = false;
    });
  }, 250);

  const onDeselect = () => {
    dispatch("deselect", property);
  };

  const onClick = () => {
    dispatch("click", property);
  };

  const loadFullIfNeeded = () => {
    if (!property.description || !property.photos || !property.floor_plans) {
      loadPropertyFull(property.zoopla_id);
    }
  };

  const resizeComment = () => {
    commentTextarea.style.height = "auto";
    commentTextarea.style.height = `${commentTextarea.scrollHeight}px`;
  };

  const formatUKPhone = (phone) => {
    if (phone[0] !== "0" || phone.length !== 11) {
      return phone
    }
    let areaCodeLen = 5
    if (phone[1] === "2") {
      areaCodeLen = 3
    } else if (phone[1] === "3" || phone[1] === "8" || phone[1] === "9") {
      areaCodeLen = 4
    } else if (phone[1] === "1" && (phone[2] === "1" || phone[3] === "1")) {
      areaCodeLen = 4
    }

    if (areaCodeLen === 3) {
      return phone.slice(0, 3) + " " + phone.slice(3, 7) + " " + phone.slice(7, 11)
    } else if (areaCodeLen === 4) {
      return phone.slice(0, 4) + " " + phone.slice(4, 7) + " " + phone.slice(7, 11)
    } else if (areaCodeLen === 5) {
      return phone.slice(0, 5) + " " + phone.slice(5, 11)
    }
    return phone
  }


  onMount(resizeComment);

  $: if (comment !== property.comment) {
    resizeComment();
    changeComment(comment);
  }

  $: googleMapURL = `https://www.google.co.uk/maps/place/${property.location.lat},${property.location.lon}`;
  $: internalPropertyURL = `property/${property.zoopla_id}`;

  $: routes = Object.entries(property.routes).map(([key, value]) => ({
    ...value,
    name: key,
  }));
</script>

<div
  bind:this={element}
  on:click={onClick}
  id={elementID}
  class="property"
  class:deleted={property.deleted}
  class:active
>
  <div class="property-title">
    <div class="property-link">
      <Link to={internalPropertyURL} class="mr-2">
        {property.title} <small>{property.zoopla_id}</small>
      </Link>
      <a href={property.url} target="_blank">
        <i class="fas fa-external-link-alt" />
      </a>
      {#if active}
        <span on:click|stopPropagation={onDeselect} class="deselect"
          >deselect</span
        >
      {/if}
    </div>
    <div class="property-address">{property.address}</div>
  </div>
  <h3 class="property-price">£{property.price}</h3>
  <div class="property-meta">
    <div>
      {#if property.bedrooms}
        <span class="mr-2">{property.bedrooms} <i class="fas fa-bed" /></span>
      {/if}
      {#if property.receptions}
        <span class="mr-2"
          >{property.receptions} <i class="fas fa-couch" /></span
        >
      {/if}
      {#if property.bathrooms}
        <span class="mr-2"
          >{property.bathrooms} <i class="fas fa-shower" /></span
        >
      {/if}
      {#if property.size_sq_meters}
        <span class="mr-2">{property.size_sq_meters.toFixed(1)}m²</span>
      {/if}
    </div>
    <div>
      <small class="mr-2">Since {property.first_listed}</small>
    </div>
  </div>
  <div class="agency-info">
    <div class="agency-name">
      {#if property.agency}
        <span class="mr-2" title={property.agency}>{property.agency}</span>
      {/if}
    </div>
    <div>
      {#if property.phone}
        <span class="mr-2">{formatUKPhone(property.phone)}</span>
      {/if}
    </div>
  </div>
  <div class="property-comment">
    <div class="input-group comment-input">
      <textarea
        class="form-control comment-text"
        bind:value={comment}
        bind:this={commentTextarea}
      />
    </div>
    {#if isCommentSending}
      <i class="fas fa-spinner fa-spin comment-spinner" />
    {/if}
  </div>
  <div class="property-buttons">
    <a
      class="btn mr-1 btn-outline-primary"
      href={googleMapURL}
      target="_blank"
      data-tooltip={"open maps"}
    >
      <i class="fas fa-map-marked-alt" />
    </a>
    <button
            data-tooltip={"mark as wanted"}
            on:click|stopPropagation={clickWanted}
            class="btn mr-1"
            class:btn-outline-secondary={!property.wanted}
            class:btn-primary={property.wanted}
    >
      <i class="fas fa-chess-king" />
    </button>
    <button
      data-tooltip={"pin"}
      on:click|stopPropagation={clickPin}
      class="btn mr-1"
      class:btn-outline-secondary={!property.pinned}
      class:btn-primary={property.pinned}
    >
      <i class="fas fa-thumbtack" />
    </button>
    <button
      data-tooltip={"mark as seen"}
      on:click|stopPropagation={clickSeen}
      class="btn mr-1"
      class:btn-outline-secondary={!property.seen}
      class:btn-primary={property.seen}
    >
      <i class="fas fa-glasses" />
    </button>
    <button
            data-tooltip={"mark as tracked"}
            on:click|stopPropagation={clickTracked}
            class="btn mr-1"
            class:btn-outline-secondary={!property.tracked}
            class:btn-primary={property.tracked}
    >
      <i class="fas fa-pen-fancy" />
    </button>
    <button
      data-tooltip={"hide"}
      on:click|stopPropagation={clickHide}
      class="btn mr-1"
      class:btn-outline-secondary={!property.hidden}
      class:btn-primary={property.hidden}
    >
      <i class="fas fa-eye-slash" />
    </button>
  </div>

  <div class="property-route-brief">
    {#each routes as route (route.name)}
      <div class="route">
        <div>
          <strong>{route.name}:</strong>
          {route.duration} min
          <small>({route.full_walking} min walk only)</small>
        </div>
        <div>{route.to_station} min to {route.station}</div>
      </div>
    {/each}
  </div>
  <div class="property-details">
    <div class="details">
      {#if property.historical_prices.length > 1}
      <details>
        <summary>Price history</summary>
        {#each property.historical_prices as hprice}
          <div class="historical-price">
            <strong>£{hprice.price}</strong> ({hprice.date})
          </div>
        {/each}
      </details>
      {/if}

      <details>
        <summary>Route details</summary>
        {#each routes as route (route.name)}
          <div class="route mr-3 mt-2">
            <strong>{route.name}</strong>
            <div class="route-details">{route.description}</div>
          </div>
        {/each}
      </details>

      <details on:click={loadFullIfNeeded}>
        <summary>Description</summary>
        {#if !property.description}
          <div class="field-loading">
            <SpinnerText size={40}>Loading description...</SpinnerText>
          </div>
        {:else}
          <div class="description my-2">{property.description}</div>
        {/if}
        <strong>Features</strong>
        <ul>
          {#each property.features as feature}
            <li>{feature}</li>
          {/each}
        </ul>
      </details>

      <details on:click={loadFullIfNeeded}>
        <summary>Photos</summary>
        {#if !property.photos}
          <div class="field-loading">
            <SpinnerText size={40}>Loading photos...</SpinnerText>
          </div>
        {:else}
          <div class="photos my-2">
            {#each property.photos as photo}
              <img class="small-photo" src={photo.small} alt="thumbnail" />
            {/each}
          </div>
        {/if}
      </details>

      {#if property.has_floor_plans}
        <details on:click={loadFullIfNeeded}>
          <summary>Floor plans</summary>
          {#if !property.floor_plans}
            <div class="field-loading">
              <SpinnerText size={40}>Loading floor plans...</SpinnerText>
            </div>
          {:else}
            <div class="floorplans my-2">
              {#each property.floor_plans as floorplan}
                <img class="floorplan" src={floorplan} alt="floorplan" />
              {/each}
            </div>
          {/if}
        </details>
      {/if}
    </div>
  </div>
</div>

<style>
  .property-title {
    grid-area: title;
  }

  .property-link {
    font-weight: 500;
  }

  .property-address {
    font-size: 0.8rem;
  }

  .property-price {
    grid-area: price;
  }

  .property-meta {
    grid-area: meta;
    font-weight: 400;
  }

  .agency-info {
    grid-area: agency;
  }

  .agency-name {
    display: block;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .property-comment {
    grid-area: comment;
    position: relative;
  }

  .property-buttons {
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
  }

  .property-route-brief {
    grid-area: route;
  }

  .property-details {
    grid-area: details;
  }

  .property-buttons .btn {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .property.active {
    background-color: #f7f7c7;
  }

  .property .deselect {
    font-size: 0.9em;
    cursor: pointer;
    border-bottom: 1px dashed black;
  }

  .field-loading {
    margin: 1em 0;
  }

  .comment-text {
    overflow: hidden;
  }

  summary {
    outline: none !important;
  }

  details {
    white-space: pre-line;
  }

  .photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .small-photo {
    margin: 7px;
  }

  .floorplan {
    max-width: 100%;
  }

  .comment-input {
    /*override boostrap style*/
    align-items: center;
  }

  .comment-spinner {
    position: absolute;
    right: 12px;
    top: 10px;
    color: hsla(0, 0%, 20%, 0.9);
    z-index: 300;
  }

  .deleted {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10em' width='20em'><text transform='translate(20, 150) rotate(-20)' fill='rgb(255,60,60)' font-weight='1000' font-size='4em' font-family='sans-serif' opacity='0.2'>DELETED</text></svg>");
  }

  [data-tooltip] {
    position: relative;
    z-index: 2;
    display: block;
  }

  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-out;
    transform: translate(-50%, 5px);
  }

  [data-tooltip]:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    padding: 7px;
    width: 100%;
    min-width: 70px;
    max-width: 250px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    transition: 0.2s ease-out;
  }

  [data-tooltip]:after {
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsla(0, 0%, 20%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }
  [data-tooltip="false"]:hover:before,
  [data-tooltip="false"]:hover:after {
    visibility: hidden;
    opacity: 0;
  }
</style>
