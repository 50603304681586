<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import FiltersForm from "../components/FiltersForm.svelte";
  import Mapbox from "../components/Mapbox.svelte";
  import MapboxDefaultMarker from "../components/MapboxDefaultMarker.svelte";
  import MapFit from "../components/MapFit.svelte";
  import PropertiesList from "../components/PropertiesList.svelte";
  import PropertyMarker from "../components/PropertyMarker.svelte";
  import SpinnerText from "../components/SpinnerText.svelte";
  import EventsList from "../components/EventsList.svelte";
  import { properties, isLoading } from "../stores/properties";
  import { events, loadEvents } from "../stores/events";
  import { destinations, loadDestinations } from "../stores/misc";
  import { filters, setupFilters, specialFilter } from "../stores/filters";
  import { error } from "../stores/messages";

  export let pinnedOnly = false;
  export let wantedOnly = false;
  export let seenOnly = false;
  export let trackedOnly = false;
  export let allMarked = false;
  export let propertyView = false;
  export let zooplaID = null;

  let lat = 51.5074;
  let lng = -0.12;
  let zoom = 10;
  let activeID = null;
  let paginatedEvents = [];
  let filtersReady = false;
  let showFiltersForm = false;
  let flags = "";
  let map = null;

  const propertyClick = (e) => {
    const property = e.detail;
    if (activeID === property.zoopla_id) {
      activeID = null;
    } else {
      activeID = property.zoopla_id;
    }
    const listItemElement = document.querySelector(
      `#property-${property.zoopla_id}`
    );
    if (listItemElement) {
      listItemElement.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  };

  const eventPropertySelect = (e) => {
    const property = e.detail;
    if (activeID !== property.zoopla_id) activeID = property.zoopla_id;

    const listItemElement = document.querySelector(
      `#property-${property.zoopla_id}`
    );
    if (listItemElement) {
      listItemElement.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }

    if (map) {
      const panel = document.querySelector(".panel");
      const bottom = panel ? panel.clientHeight : 0;
      map.flyTo({
        center: [property.location.lon, property.location.lat],
        //padding: { bottom },
      });
    }
  };

  const propertySelect = (e) => {
    const property = e.detail;
    if (activeID !== property.zoopla_id) activeID = property.zoopla_id;
    if (map) {
      const panel = document.querySelector(".panel");
      const bottom = panel ? panel.clientHeight : 0;
      map.flyTo({
        center: [property.location.lon, property.location.lat],
        //padding: { bottom },
      });
    }
  };

  const propertyDeselect = () => {
    activeID = null;
  };

  $: paginatedEvents = $events.slice(0, $filters.events);
  $: showFiltersForm = filtersReady && !$specialFilter;
  $: if (
    ($specialFilter && $specialFilter.pinnedOnly) ||
    $properties.length >= 1000
  ) {
    const flagsArray = [];

    if ($specialFilter && $specialFilter.pinnedOnly) {
      flagsArray.push("pinned only");
    }

    if ($specialFilter && $specialFilter.wantedOnly) {
      flagsArray.push("wanted only");
    }

    if ($specialFilter && $specialFilter.seenOnly) {
      flagsArray.push("seen only");
    }

    if ($specialFilter && $specialFilter.trackedOnly) {
      flagsArray.push("tracked only");
    }

    if ($specialFilter && $specialFilter.allMarked) {
      flagsArray.push("all marked");
    }

    if ($properties.length >= 1000) {
      flagsArray.push("limit");
    }
    flags = "(" + flagsArray.join(", ") + ")";
  } else {
    flags = "";
  }

  const eventsReload = () => {
    loadEvents();
    setTimeout(eventsReload, 60000);
  };

  $: if (pinnedOnly) {
    specialFilter.set({pinnedOnly: true});
  } else if (wantedOnly) {
    specialFilter.set({wantedOnly: true});
  } else if (seenOnly) {
    specialFilter.set({seenOnly: true});
  } else if (trackedOnly) {
    specialFilter.set({trackedOnly: true});
  } else if (allMarked) {
    specialFilter.set({allMarked: true});
  } else if (propertyView) {
    console.log("in property view", zooplaID);
    const zid = parseInt(zooplaID);
    if (isNaN(zid)) {
      error(`zoopla id "${zooplaID}" is invalid`);
      router("/");
    }
    specialFilter.set({ propertyView: zid });
  } else {
    specialFilter.set(null);
    setupFilters().then(() => {
      filtersReady = true;
    });
  }

  onMount(async () => {
    loadDestinations();
    eventsReload();
  });
</script>

<div id="map" class="map-reduced">
  <Mapbox {lat} {lng} {zoom} bind:map>
    {#each $properties as property (property.zoopla_id)}
      <PropertyMarker
        active={activeID === property.zoopla_id}
        {property}
        on:click={propertyClick}
      />
    {/each}
    {#each $destinations as dst (dst.name)}
      <MapboxDefaultMarker lat={dst.lat} lng={dst.lng} color="#822659" />
    {/each}
    <MapFit />
  </Mapbox>
</div>
<div class="panel panel-expanded">
  {#if showFiltersForm}
    <FiltersForm />
  {:else if $properties.length}
    <div class="mb-2">
      <Link to="/">&larr; Back to filters</Link>
    </div>
  {/if}
  <EventsList on:click={eventPropertySelect} events={paginatedEvents} />
  {#if $isLoading}
    <div class="loading">
      <SpinnerText>Loading Properties...</SpinnerText>
    </div>
  {:else}
    <PropertiesList
            on:deselect={propertyDeselect}
            on:click={propertySelect}
            {activeID}
    >
      {#if $specialFilter && $specialFilter.propertyView}
        <h5>Single Property View</h5>
      {:else}
        <h5>
          {$properties.length} properties {flags}
        </h5>
      {/if}
    </PropertiesList>
  {/if}
</div>

<style>
  h5 {
    text-align: center;
    margin: 1em 0;
  }
</style>
