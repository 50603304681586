<script>
  import EventListItem from "./EventsListItem.svelte";
  export let events;
</script>

<div class="events-list">
  {#each events as event}
    <EventListItem on:click {event} />
  {/each}
</div>
