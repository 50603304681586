import axios, { CancelToken } from "axios";
import { error, info } from "../stores/messages";

let cancelFilterRequest = null;

const ErrorHandler = (err) => {
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.error) {
        error(err.response.data.error);
      } else {
        error(err.response.data);
      }
    } else {
      error(err.response.statusText);
    }
  }

  return undefined;
};

const wrapper = (request) => {
  return new Promise((resolve, reject) => {
    request
      .then((response) => {
        if (response.data && response.data.message) {
          info(response.data.message);
        }
        resolve(response);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          reject(ErrorHandler(err));
        }
      });
  });
};

const filterRequest = (url) => {
  if (cancelFilterRequest) {
    cancelFilterRequest();
  }
  const req = axios.get(url, {
    cancelToken: new CancelToken((c) => {
      cancelFilterRequest = c;
    }),
  });
  return req;
};

const api = {
  filter(options) {
    const query = new URLSearchParams(options);
    const url = `/api/filter?${query.toString()}`;
    const req = filterRequest(url);
    return wrapper(req);
  },
  wanted() {
    const req = filterRequest("/api/wanted");
    return wrapper(req);
  },
  pinned() {
    const req = filterRequest("/api/pinned");
    return wrapper(req);
  },
  seen() {
    const req = filterRequest("/api/seen");
    return wrapper(req);
  },
  tracked() {
    const req = filterRequest("/api/tracked");
    return wrapper(req);
  },
  marked() {
    const req = filterRequest("/api/marked");
    return wrapper(req);
  },
  events() {
    return wrapper(axios.get("/api/events"));
  },
  destinations() {
    return wrapper(axios.get("/api/destinations"));
  },
  property(zooplaID, cancel = true) {
    const url = `/api/property/${zooplaID}`;
    const req = cancel ? filterRequest(url) : axios.get(url);
    return wrapper(req);
  },
  option(zooplaID, optionName, value) {
    const url = `/api/property/${zooplaID}/${optionName}`;
    return wrapper(axios.post(url, { [optionName]: value }));
  },
  types() {
    return wrapper(axios.get("/api/types"));
  },
};

export default api;
