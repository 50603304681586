<script>
  import { properties } from "../stores/properties";
  import PropertiesListItem from "./PropertiesListItem.svelte";
  export let activeID = null;
</script>

<div class="properties-list">
  <slot />
  {#each $properties as property (property.zoopla_id)}
    <PropertiesListItem
      on:deselect
      on:click
      {property}
      active={property.zoopla_id === activeID}
    />
  {/each}
</div>
