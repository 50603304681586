<script>
  import {
    createEventDispatcher,
    onDestroy,
    onMount,
    setContext,
  } from "svelte";
  import { mapCtx } from "../Context";
  import mapboxgl from "maplibre-gl";
  import "maplibre-gl/dist/maplibre-gl.css";

  const MAPTILER_STYLE_URL =
    "https://track-my-flight.live/api/maps/bright/style.json";

  export let lat;
  export let lng;
  export let zoom = 5;
  export let images = {};
  export let map = null;

  const dispatch = createEventDispatcher();
  setContext(mapCtx, { getMap: () => map });

  let container;
  let loaded = false;

  const onBounds = () => {
    const bounds = map.getBounds();
    dispatch("bounds", bounds);
  };

  const onBoundsStart = () => {
    dispatch("boundsstart");
  };

  const setupEventListeners = () => {
    window.map = map;
    window.addEventListener("resize", onBoundsStart);
    map.on("zoomstart", onBoundsStart);
    map.on("movestart", onBoundsStart);
    map.on("zoomend", onBounds);
    map.on("moveend", onBounds);
    map.on("resize", onBounds);
  };

  const removeEventListeners = () => {
    window.removeEventListener("resize", onBoundsStart);
    map.off("zoomstart", onBoundsStart);
    map.off("movestart", onBoundsStart);
    map.off("zoomend", onBounds);
    map.off("moveend", onBounds);
    map.off("resize", onBounds);
  };

  const loadImages = async () => {
    const promises = [];
    for (const name in images) {
      promises.push(
        new Promise((resolve, reject) => {
          map.loadImage(images[name], (error, image) => {
            if (error) {
              reject(error);
            } else {
              resolve({ name, image });
            }
          });
        })
      );
    }
    Promise.all(promises).then((values) => {
      values.forEach((value) => {
        map.addImage(value.name, value.image);
      });
    });
  };

  onMount(() => {
    map = new mapboxgl.Map({
      container,
      style: MAPTILER_STYLE_URL,
      center: [lng, lat],
      zoom,
    });

    map.on("load", async () => {
      await loadImages();
      loaded = true;
      dispatch("ready");
      onBounds();
    });

    setupEventListeners();
  });

  onDestroy(() => {
    removeEventListeners();
    map.remove();
    map = null;
  });
</script>

<div class="map" bind:this={container}>
  {#if loaded}
    <slot />
  {/if}
</div>

<style>
  .map {
    width: 100%;
    height: 100%;
  }
</style>
