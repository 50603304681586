import { derived, readable, writable } from "svelte/store";
import api from "../api";

const createFilters = () => {
  const LS_KEY = "userFilters";
  let filterData = null;

  const stored = localStorage.getItem(LS_KEY);

  if (stored) {
    try {
      filterData = JSON.parse(stored);
      if (filterData.wanted === undefined) {
        filterData.wanted = false;
      }
      if (filterData.show_tracked === undefined) {
        filterData.show_tracked = false;
      }
    } catch (e) {}
  }

  if (!filterData) {
    filterData = {
      garden: {
        value: { id: 0, text: "Yes" },
        options: [
          { id: 0, text: "Yes" },
          { id: 1, text: "No" },
          { id: 2, text: "Ignore" },
        ],
      },
      acre: {
        value: { id: 0, text: "Yes" },
        options: [
          { id: 0, text: "Yes" },
          { id: 1, text: "No" },
          { id: 2, text: "Ignore" },
        ],
      },
      parking: {
        value: { id: 0, text: "Yes" },
        options: [
          { id: 0, text: "Yes" },
          { id: 1, text: "No" },
          { id: 2, text: "Ignore" },
        ],
      },
      propType: null,
      minBeds: 2,
      maxBeds: 3,
      minBaths: 1,
      maxBaths: 2,
      minPrice: 1400,
      maxPrice: 2000,
      maxTravel: 40,
      events: 10,
      wanted: false,
      pinned: false,
      hidden: false,
      seen: false,
      tracked: false,
      openPlan: false,
      unfurnished: false,
      floor_plans_only: false,
      minAreaSqM: 0,
      maxAreaSqM: 0,
      includeNoArea: false,
      includeNoCommute: false,
    };
  }

  const parkingOption = filterData.parking.options.find(
    ({ id }) => id === filterData.parking.value.id
  );
  filterData.parking.value = parkingOption;

  const gardenOption = filterData.garden.options.find(
    ({ id }) => id === filterData.garden.value.id
  );
  filterData.garden.value = gardenOption;

  const acreOption = filterData.acre.options.find(
    ({ id }) => id === filterData.acre.value.id
  );
  filterData.acre.value = acreOption;

  const filters = writable(filterData);
  filters.subscribe((value) => {
    localStorage.setItem(LS_KEY, JSON.stringify(value));
  });

  return filters;
};

export const filters = createFilters();
export const specialFilter = writable(null);
export const setupFilters = async () => {
  return new Promise((resolve) => {
    const unsub = filters.subscribe((value) => {
      api.types().then((response) => {
        let data = response.data;
        data.sort();
        data = ["any", ...data];
        const options = data.map((item, idx) => {
          return {
            id: idx,
            text: item,
            label: item.replaceAll("_", " "),
          };
        });

        let { propType } = value;

        let idx;
        if (propType && propType.value) {
          idx = options.findIndex((item) => {
            return item.id === propType.value.id;
          });
        } else {
          idx = options.indexOf("flat");
        }

        propType = {
          value: options[idx],
          options,
        };

        filters.update((f) => {
          return {
            ...f,
            propType,
          };
        });
        unsub();
        resolve();
      });
    });
  });
};

export const combinedFilters = derived(
  [filters, specialFilter],
  ([$filters, $specialFilter]) => {
    return {
      filters: $filters,
      specialFilter: $specialFilter,
    };
  }
);
