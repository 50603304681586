<script>
  import { messages, removeMessage } from "../stores/messages";
  import { fly } from "svelte/transition";
</script>

<div class="alert-box">
  {#each $messages as message (message.id)}
    <div
      transition:fly={{ x: 200 }}
      class={`alert alert-dismissible ${message.classes}`}
    >
      {#if message.prefix}
        <b>{message.prefix}</b>
      {/if}
      {message.text}
      <button on:click={removeMessage(message.id)} type="button" class="close">
        &times;
      </button>
    </div>
  {/each}
</div>

<style>
  .alert-box {
    position: fixed;
    right: 14px;
    top: 70px;
    max-width: 80%;
    width: auto;
    z-index: 3000;
  }

  .alert {
    margin-bottom: 4px;
    width: max-content;
    margin-left: auto;
  }

  .alert.alert-dismissable .close:hover {
    color: #fcfcfc;
  }
</style>
