<script>
  import mapboxgl from "maplibre-gl";
  import {
    createEventDispatcher,
    getContext,
    onDestroy,
    onMount,
  } from "svelte";
  import { mapCtx } from "../Context";

  const map = getContext(mapCtx).getMap();
  const dispatch = createEventDispatcher();

  export let lat;
  export let lng;
  export let color = "#A33";

  let marker;
  let colorSetOnce = false;

  const createMarker = () => {
    marker = new mapboxgl.Marker({ color }).setLngLat([lng, lat]).addTo(map);
    const element = marker.getElement();
    element.addEventListener("click", markerClick);
  };

  const removeMarker = () => {
    if (!marker) return;
    const element = marker.getElement();
    element.removeEventListener("click", markerClick);
    marker.remove();
    marker = null;
  };

  const markerClick = () => {
    dispatch("click", { marker });
  };

  $: if (marker) {
    marker.setLngLat([lng, lat]);
  }

  $: if (marker && color) {
    if (!colorSetOnce) {
      colorSetOnce = true;
    } else {
      removeMarker();
      createMarker();
    }
  }

  onMount(() => {
    createMarker();
  });

  onDestroy(() => {
    removeMarker();
  });
</script>
