<script>
  import {createEventDispatcher} from "svelte"

  export let event;

  const dispatch = createEventDispatcher();

  const onClick = () => {
    dispatch("click", event.prop);
  };
</script>

<div class="event w-100">
  <div class="d-inline-block"  on:click={onClick}>
    <small class="mr-2">{event.desc}</small>
    <a href={event.url} target="_blank" class="mr-2">
      <small>{event.zoopla_id}</small>
    </a>
    <small class="mr-2">{event.timestamp}</small>
    <small class="mr-2">£{event.price}</small>
    {#if event.prop.hidden}
      <small class="mr-2 hidden"><i class="fas fa-eye-slash" /></small>
    {/if}
    {#if event.prop.pinned}
      <small class="mr-2 pinned"><i class="fas fa-thumbtack" /></small>
    {/if}
    {#if event.prop.seen}
      <small class="mr-2 seen"><i class="fas fa-glasses" /></small>
    {/if}
    {#if event.prop.wanted}
      <small class="mr-2 wanted"><i class="fas fa-chess-king" /></small>
    {/if}
    {#if event.prop.tracked}
      <small class="mr-2 tracked"><i class="fas fa-pen-fancy" /></small>
    {/if}
    {#if event.prop.comment}
      <small class="mr-2">{event.prop.comment}</small>
    {/if}
  </div>
</div>

<style>
  .hidden,
  .seen,
  .wanted,
  .tracked,
  .pinned {
    font-size: 0.8em;
  }
</style>
