import { writable } from "svelte/store";

const DEFAULT_ALERT_TTL = 8000;
const TYPE2CLASS = {
  alert: "alert-danger",
  error: "alert-danger",
  info: "alert-info",
  warning: "alert-warning",
  success: "alert-success",
};

export const messages = writable([]);

export const removeMessage = (messageID) => {
  messages.update((value) => {
    const msg = value.find((msg) => msg.id === messageID);
    if (!msg) {
      return value;
    }

    clearTimeout(msg.timeout);
    return value.filter((msg) => {
      msg.id !== messageID;
    });
  });
};

export const addMessage = (msg) => {
  if (!msg.id) {
    msg.id = new Date().getTime();
  }

  if (!msg.timeout) {
    msg.timeout = DEFAULT_ALERT_TTL;
  }

  if (!msg.type) {
    msg.type = "info";
  }

  msg.classes = TYPE2CLASS[msg.type];

  msg.tm = setTimeout(() => {
    removeMessage(msg.id);
  }, msg.timeout);

  messages.update((value) => [...value, msg]);
};

export const alert = (text) => {
  addMessage({ text, type: "error" });
};

export const error = (text) => {
  addMessage({ text, type: "error", prefix: "Error:" });
};

export const info = (text) => {
  addMessage({ text, type: "info" });
};

export const warning = (text) => {
  addMessage({ text, type: "warning" });
};

export const success = (text) => {
  addMessage({ text, type: "success" });
};
